import { gql } from '@apollo/client/core';
import {
	GROUP_CORE,
	MULTIMEDIA_CONTENT_FULL,
	OUTPUT_CORE,
	SECTION_CORE,
	TEAM_CORE,
	TOOL_CORE,
	USER_PRIVATE_INFO,
	USER_PUBLIC_INFO
} from './fragments';

export const GET_SECTIONS_BASIC_ACTIVE_BY_TEAM = gql`
	query GetSectionsBasic($team_id: uuid!) {
		sections(
			where: {
				contents_sections: {
					content: {
						groups: {
							_and: { active: { _eq: true }, group: { teams: { team_id: { _eq: $team_id } } } }
						}
					}
				}
			}
		) {
			id
			name
			slug
			hex_color
			intro
			section_icons_files {
				type
				file {
					path
				}
			}
		}
	}
`;

export const SECTIONS_BASIC_ACTIVE_BY_GROUP = gql`
	query SectionsBasicByGroup($group_id: uuid!) {
		sections(
			where: {
				contents_sections: {
					content: {
						groups: { _and: { active: { _eq: true }, group: { id: { _eq: $group_id } } } }
					}
				}
			}
		) {
			id
			name
			slug
			hex_color
			intro
			section_icons_files {
				type
				file {
					path
				}
			}
		}
	}
`;

export const GET_SECTIONS_CORE_ACTIVE_BY_TEAM = gql`
	${SECTION_CORE}
	query GetSectionsCore($team_id: uuid!) {
		sections(
			where: {
				contents_sections: {
					content: {
						groups: {
							_and: { active: { _eq: true }, group: { teams: { team_id: { _eq: $team_id } } } }
						}
					}
				}
			}
		) {
			...SectionCore
		}
	}
`;
export const GET_OUTPUTS_CORE_BY_SECTION = gql`
	${OUTPUT_CORE}
	query GetOutputsCoresBySection($sectionId: uuid!) {
		outputs(where: { parent_section_id: { _eq: $sectionId } }, order_by: { order: asc }) {
			...OutputCore
		}
	}
`;
export const GET_OUTPUT_CORE_BY_SLUG = gql`
	${OUTPUT_CORE}
	query GetOutputCoreBySlug($slug: String!) {
		outputs(where: { slug: { _eq: $slug } }) {
			...OutputCore
		}
	}
`;
export const GET_OUTPUT_CORE_MULTIMEDIA_BY_SLUG = gql`
	${OUTPUT_CORE}
	${MULTIMEDIA_CONTENT_FULL}
	query GetOutputCoreMultimediaBySlug($slug: String!) {
		outputs(where: { slug: { _eq: $slug } }) {
			...OutputCore
			multimedia_content {
				...MultimediaContentFull
			}
		}
	}
`;
export const GET_OUTPUT_TOOLS_CORE = gql`
	${TOOL_CORE}
	query GetToolsCoreByOutput($outputId: uuid!) {
		outputs_tools(where: { output_id: { _eq: $outputId } }, order_by: { order: asc }) {
			order
			tool {
				...ToolCore
			}
		}
	}
`;
export const GET_TOOL_CORE_MULTIMEDIA_BY_SLUG = gql`
	${TOOL_CORE}
	${MULTIMEDIA_CONTENT_FULL}
	query GetToolCoreMultimediaBySlug($slug: String!) {
		tools(where: { slug: { _eq: $slug } }) {
			...ToolCore
			multimedia_content {
				...MultimediaContentFull
			}
		}
	}
`;
export const GET_QUESTIONNAIRE_ACTIVE_OF_TOOL = gql`
	query QuestionnaireActiveOfTool($tool_id: uuid!, $team_id: uuid!) {
		questionnaires(where: { _and: { tool_id: { _eq: $tool_id }, active: { _eq: true } } }) {
			id
			questions(order_by: { order: asc }) {
				id
				order
				title
				subtitle
				type
				question_answers(where: { team_id: { _eq: $team_id } }) {
					answer
				}
			}
			teams_states(where: { team_id: { _eq: $team_id } }) {
				updated_at
				finished
			}
		}
	}
`;
export const SIGN_UP_USER = gql`
	mutation SignUpUser(
		$cpf: String!
		$email: String!
		$name: String!
		$password: String!
		$address_doc_b64str: String!
		$id_doc_b64str: String!
		$token: String!
		$team_id: String
		$dob: date!
		$phone: String!
		$cep: String!
		$state: String!
		$city: String!
		$address: String!
		$secondLine: String
	) {
		createUser(
			cpf: $cpf
			email: $email
			name: $name
			password: $password
			address_doc_b64str: $address_doc_b64str
			id_doc_b64str: $id_doc_b64str
			token: $token
			team_id: $team_id
			dob: $dob
			phone: $phone
			cep: $cep
			state: $state
			city: $city
			address: $address
			secondLine: $secondLine
		)
	}
`;
export const SIGN_UP_USER_SUBSCRIBE = gql`
	subscription SignUpSubscription($action_id: uuid!) {
		createUser(id: $action_id) {
			errors
			output {
				id
				name
				email
				cpf
			}
		}
	}
`;
export const GET_USER_CORE_BY_ID = gql`
	${USER_PRIVATE_INFO}
	${USER_PUBLIC_INFO}
	query GetUserCore($id: String!) {
		users(where: { id: { _eq: $id } }) {
			...UserPrivateInfo
			...UserPublicInfo
		}
	}
`;
export const GET_TEAM_CORE_BY_ID = gql`
	${TEAM_CORE}
	query GetTeamCoreById($id: uuid!) {
		teams_by_pk(id: $id) {
			...TeamCore
		}
	}
`;
export const GET_TEAMS_OF_USER = gql`
	${TEAM_CORE}
	query GetTeamsOfUser($user_id: String!) {
		teams(where: { members: { user_id: { _eq: $user_id } } }) {
			...TeamCore
		}
	}
`;
export const GET_GROUPS_OF_TEAM = gql`
	${GROUP_CORE}
	query GetGroupsOfTeam($team_id: uuid!) {
		groups(where: { teams: { team_id: { _eq: $team_id } } }) {
			...GroupCore
		}
	}
`;
export const GET_TEAM_INVITES_OF_USER = gql`
	${TEAM_CORE}
	query GetTeamCore($user_id: String!) {
		team_invites(where: { _and: { user_id: { _eq: $user_id }, status: { _eq: pending } } }) {
			id
			team {
				...TeamCore
			}
		}
	}
`;
export const RESPOND_TEAM_INVITATION = gql`
	mutation RespondTeamInvitation($invite_id: String!, $accept: Boolean!) {
		respondTeamInvitation(invite_id: $invite_id, accept: $accept) {
			status
			team_id
			user_id
		}
	}
`;
export const COUNT_TEAM_SCORE = gql`
	query GetTeamScore($team_id: uuid!) {
		tools_aggregate(
			where: {
				questionnaires: {
					teams_states: { _and: { finished: { _eq: true }, team_id: { _eq: $team_id } } }
				}
			}
		) {
			aggregate {
				sum {
					points
				}
			}
		}
	}
`;
export const GET_TEAMS_SCORES_BY_GROUP = gql`
	query GetTeamsScoresByGroup($group_id: uuid!) {
		teams_scores(
			order_by: { sum: desc }
			where: { team: { groups: { group_id: { _eq: $group_id } } } }
		) {
			sum
			team {
				id
				names(limit: 1, order_by: { created_at: desc }) {
					name
				}
			}
		}
	}
`;
export const COUNT_TOOLS_DONE_OF_SECTION_BY_TEAM = gql`
	query CountToolsDoneOfSectionByTeam($team_id: uuid!, $section_id: uuid!) {
		done: tools_aggregate(
			where: {
				_and: {
					questionnaires: {
						teams_states: { _and: { finished: { _eq: true }, team_id: { _eq: $team_id } } }
					}
					outputs: { output: { parent_section_id: { _eq: $section_id } } }
				}
			}
		) {
			aggregate {
				count
			}
		}
		all: tools_aggregate(
			where: { outputs: { output: { parent_section_id: { _eq: $section_id } } } }
		) {
			aggregate {
				count
			}
		}
	}
`;
export const COUNT_TOOLS_DONE_OF_SECTION_BY_TEAM_GET_OUTPUTS_NODES = gql`
	query CountToolsDoneOfSectionByTeamGetOutputsNodes($team_id: uuid!, $section_id: uuid!) {
		done: tools_aggregate(
			where: {
				_and: {
					questionnaires: {
						teams_states: { _and: { finished: { _eq: true }, team_id: { _eq: $team_id } } }
					}
					outputs: { output: { parent_section_id: { _eq: $section_id } } }
				}
			}
		) {
			nodes {
				outputs {
					output_id
				}
			}
		}
		all: tools_aggregate(
			where: { outputs: { output: { parent_section_id: { _eq: $section_id } } } }
		) {
			nodes {
				outputs {
					output_id
				}
			}
		}
	}
`;
export const GET_OUTPUTS_BY_SECTION_COUNT_TOOLS_DONE_BY_TEAM = gql`
	${OUTPUT_CORE}
	query GetOutputsDoneBySection($section_id: uuid!, $team_id: uuid!) {
		outputs(where: { parent_section_id: { _eq: $section_id } }) {
			...OutputCore
			done: tools_aggregate(
				where: {
					tool: {
						questionnaires: {
							teams_states: { _and: { finished: { _eq: true }, team_id: { _eq: $team_id } } }
						}
					}
				}
			) {
				aggregate {
					count
				}
			}
			all: tools_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;
export const GET_LOCATIONS_CALENDARS = gql`
	query GetLocationsCalendars($team_id: uuid!) {
		default: locations(where: { groups: { teams: { team_id: { _eq: $team_id } } } }) {
			calendar_id
			names(order_by: { created_at: desc }) {
				name
			}
		}
		all: locations(order_by: { names_aggregate: { min: { name: asc } } }) {
			calendar_id
			names(order_by: { created_at: desc }) {
				name
			}
		}
	}
`;
export const GET_TAGS = gql`
	query GetTags {
		tags {
			id
			value
		}
	}
`;
export const GET_MEMBERS_OF_TEAM = gql`
	${USER_PUBLIC_INFO}
	query GetMembersOfTeam($team_id: uuid!) {
		users(where: { teams: { team_id: { _eq: $team_id } } }) {
			role
			...UserPublicInfo
		}
	}
`;
export const UPLOAD_PROFILE_PICTURE = gql`
	mutation UploadProfilePicture($user_id: String!, $b64_img: String!) {
		uploadProfilePicture(user_id: $user_id, b64_img: $b64_img) {
			id
			path
		}
	}
`;
export const INSERT_USER_NAME = gql`
	mutation UpdateUserName($user_id: String!, $name: String!) {
		insert_users_names_one(object: { user_id: $user_id, name: $name }) {
			name
			user_id
		}
	}
`;
export const UPDATE_USER_OCCUPATION = gql`
	mutation UpdateUserOccupation($user_id: String!, $occupation: String!) {
		update_users(where: { id: { _eq: $user_id } }, _set: { occupation: $occupation }) {
			returning {
				id
				occupation
			}
		}
	}
`;
export const UPDATE_TEAM = gql`
	mutation UpdateTeam($team_id: uuid!, $cnpj: String, $description: String) {
		update_teams_by_pk(
			pk_columns: { id: $team_id }
			_set: { cnpj: $cnpj, description: $description }
		) {
			id
			cnpj
			description
		}
	}
`;
export const INSERT_TEAM_NAME = gql`
	mutation InsertTeamName($team_id: uuid!, $name: String!) {
		insert_teams_names_one(object: { team_id: $team_id, name: $name }) {
			team_id
			name
		}
	}
`;
export const DELETE_TEAM_MEMBER = gql`
	mutation DeleteTeamMember($team_id: uuid!, $user_id: String!) {
		delete_teams_users(
			where: { _and: { team_id: { _eq: $team_id }, user_id: { _eq: $user_id } } }
		) {
			returning {
				team_id
			}
		}
	}
`;
export const UPDATE_SOCIAL_MEDIA_OF_TEAM = gql`
	mutation UpdateTeamSocialMedia(
		$team_id: uuid!
		$facebook: String
		$twitter: String
		$instagram: String
		$youtube: String
		$linkedin: String
		$other: String
	) {
		update_social_medias(
			where: { teams: { id: { _eq: $team_id } } }
			_set: {
				youtube: $youtube
				twitter: $twitter
				other: $other
				linkedin: $linkedin
				instagram: $instagram
				facebook: $facebook
			}
		) {
			returning {
				facebook
				instagram
				linkedin
				other
				twitter
				youtube
			}
		}
	}
`;
export const INSERT_TAGS_TO_TEAM = gql`
	mutation InsertTagsToTeam($objects: [teams_tags_insert_input!]!) {
		# teams_tags_insert_input:
		#   {team_id: $team_id, tag_id: $tag_id} case tag exists
		#   {tag: {data: {value: $value}}, team_id: $team_id} case tag doesnt exist
		insert_teams_tags(objects: $objects) {
			returning {
				tag_id
				team_id
			}
		}
	}
`;
export const DELETE_TAGS_OF_TEAM = gql`
	mutation InsertNewTag($team_id: uuid!) {
		delete_teams_tags(where: { team_id: { _eq: $team_id } }) {
			affected_rows
		}
	}
`;
export const GET_DIAGNOSTICS_TABS_ACTIVE_BY_TEAM = gql`
	query GetDiagnosticsTabsActiveByTeam($team_id: uuid!) {
		diagnostics_tabs(
			order_by: { order: asc }
			where: {
				diagnostic: {
					groups: {
						_and: { active: { _eq: true }, group: { teams: { team_id: { _eq: $team_id } } } }
					}
				}
			}
		) {
			id
			order
			title
		}
	}
`;
export const GET_DIAGNOSTICS_SECTIONS_BY_TAB = gql`
	query GetDiagnosticsSectionsByTab($tab_id: uuid!) {
		diagnostics_tabs_sections(order_by: { order: asc }, where: { tab_id: { _eq: $tab_id } }) {
			order
			section {
				id
				title
			}
		}
	}
`;

export const GET_DIAGNOSTICS_SUBSECTIONS_BY_TAB_SECTION_AND_TEAM = gql`
	query GetDiagnosticsSubsectionsBySectionAndTeam(
		$tab_id: uuid!
		$section_id: uuid!
		$team_id: uuid!
	) {
		diagnostics_subsections(order_by: { order: asc }, where: { section_id: { _eq: $section_id } }) {
			id
			order
			title
			questions(order_by: { order: asc }) {
				id
				order
				question
				answers(where: { _and: { tab_id: { _eq: $tab_id }, team_id: { _eq: $team_id } } }) {
					id
					answer
				}
			}
		}
	}
`;
export const STORE_DIAGNOSTICS_QUESTIONS_ANSWERS_BY_TAB_QUESTION_AND_TEAM = gql`
	mutation StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeam(
		$objects: [diagnostics_questions_answers_insert_input!]!
	) {
		# [diagnostics_questions_answers_insert_input!]
		#   {answer: $answer, question_id: $question_id, tab_id: $tab_id, team_id: $team_id}
		insert_diagnostics_questions_answers(
			objects: $objects
			on_conflict: {
				constraint: diagnostics_questions_answers_group_id_question_id_tab_id_key
				update_columns: answer
			}
		) {
			returning {
				id
				answer
			}
		}
	}
`;
export const GET_DIAGNOSTICS_COMMENT_BY_TAB_AND_TEAM = gql`
	query GetDiagnosticsCommentByTabAndTeam($tab_id: uuid!, $team_id: uuid!) {
		diagnostics_comments_by_pk(diagnostic_tab_id: $tab_id, team_id: $team_id) {
			comment
		}
	}
`;
export const STORE_DIAGNOSTICS_COMMENT_BY_TAB_AND_TEAM = gql`
	mutation StoreDiagnosticsCommentByTabAndTeam($comment: String!, $tab_id: uuid!, $team_id: uuid!) {
		insert_diagnostics_comments_one(
			object: { comment: $comment, diagnostic_tab_id: $tab_id, team_id: $team_id }
			on_conflict: { constraint: diagnostics_comments_pkey, update_columns: comment }
		) {
			comment
		}
	}
`;
export const GET_VIDEOS_BY_TEAM_PAGINATED = gql`
	# order_by: asc OR desc
	query GetVideosByTeam(
		$team_id: uuid!
		$limit: Int!
		$offset: Int!
		$order: order_by!
		$contains_text: String!
	) {
		videos(
			limit: $limit
			offset: $offset
			order_by: { published_at: $order }
			where: {
				_and: {
					repository: {
						content: {
							groups: {
								_and: { active: { _eq: true }, group: { teams: { team_id: { _eq: $team_id } } } }
							}
						}
					}
					_or: [{ description: { _ilike: $contains_text } }, { title: { _ilike: $contains_text } }]
				}
			}
		) {
			description
			title
			id
			link
			published_at
			tags {
				tag
			}
			thumbnail
		}
	}
`;

export const COUNT_VIDEOS_BY_TAGS_BY_TEAM = gql`
	query CountVideosByTagsByTeam($tags: _text!, $team_id: uuid!, $contains_text: String!) {
		videos_by_tags_aggregate(
			args: { tags: $tags }
			where: {
				_and: {
					repository: {
						content: {
							groups: {
								_and: { active: { _eq: true }, group: { teams: { team_id: { _eq: $team_id } } } }
							}
						}
					}
					_or: [{ description: { _ilike: $contains_text } }, { title: { _ilike: $contains_text } }]
				}
			}
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_VIDEOS_BY_TAGS_BY_TEAM_PAGINATED = gql`
	# order_by: asc OR desc
	# tags: '{Tag1,Tag2,Tag3}' hasura is weird with this
	query GetVideosByTagsByTeam(
		$tags: _text!
		$team_id: uuid!
		$limit: Int!
		$offset: Int!
		$order: order_by!
		$contains_text: String!
	) {
		videos_by_tags(
			args: { tags: $tags }
			limit: $limit
			offset: $offset
			order_by: { published_at: $order }
			where: {
				_and: {
					repository: {
						content: {
							groups: {
								_and: { active: { _eq: true }, group: { teams: { team_id: { _eq: $team_id } } } }
							}
						}
					}
					_or: [{ description: { _ilike: $contains_text } }, { title: { _ilike: $contains_text } }]
				}
			}
		) {
			description
			title
			id
			link
			published_at
			tags {
				tag
			}
			thumbnail
		}
	}
`;
export const GET_YOUTUBE_TAGS = gql`
	query GetYoutubeTags {
		youtube_tags {
			tag
		}
	}
`;
export const GET_REPOSITORIES_ACTIVE_BY_TEAM = gql`
	query GetRepositoriesActiveByTeam($team_id: uuid!) {
		repositories(
			where: {
				content: {
					groups: {
						_and: { active: { _eq: true }, group: { teams: { team_id: { _eq: $team_id } } } }
					}
				}
			}
		) {
			id
			link
			app_key
		}
	}
`;
export const STORE_QUESTIONS_ANSWERS_BY_QUESTION_BY_TEAM = gql`
	# $answers: {team_id: uuid, question_id: uuid, answer: str}
	mutation StoreQuestionsAnswersByQuestionByTeam($answers: [questions_answers_insert_input!]!) {
		insert_questions_answers(
			objects: $answers
			on_conflict: { constraint: questions_answers_question_id_team_id_key, update_columns: answer }
		) {
			affected_rows
		}
	}
`;
export const STORE_QUESTIONNAIRE_TEAM_STATE = gql`
	mutation StoreQuestionnaireTeamState(
		$questionnaire_id: uuid!
		$team_id: uuid!
		$finished: Boolean!
	) {
		insert_questionnaires_teams_states_one(
			object: { questionnaire_id: $questionnaire_id, team_id: $team_id, finished: $finished }
			on_conflict: { constraint: questionnaires_teams_states_pkey, update_columns: finished }
		) {
			finished
		}
	}
`;
export const GET_MENTORS_FEEDBACK_BY_TOOL_BY_TEAM = gql`
	query GetMentorsFeedbackByToolByTeam($team_id: uuid!, $tool_id: uuid!) {
		mentors_blablabla(where: { _and: { team_id: { _eq: $team_id }, tool_id: { _eq: $tool_id } } }) {
			id
			value
		}
	}
`;
export const STORE_MENTORS_FEEDBACK = gql`
	mutation StoreMentorsFeedback($team_id: uuid!, $tool_id: uuid!, $value: String!) {
		insert_mentors_blablabla_one(
			object: { team_id: $team_id, tool_id: $tool_id, value: $value }
			on_conflict: { constraint: mentors_blablabla_team_id_tool_id_key, update_columns: value }
		) {
			value
		}
	}
`;
export const GET_BLOB_STORAGE_SAS_URI = gql`
	query GetBlobStorageSASUri($container: String!, $filename: String!) {
		getStorageSASUri(container: $container, filename: $filename)
	}
`;
export const INSERT_MENTORING_RECORD = gql`
	mutation InsertMentoringRecord(
		$date: timestamptz!
		$record: String!
		$team_id: uuid!
		$duration: smallint!
	) {
		insert_mentoring_records_one(
			object: { date: $date, record: $record, team_id: $team_id, duration: $duration }
		) {
			id
		}
	}
`;
export const DELETE_MENTORING_RECORD_BY_ID = gql`
	mutation DeleteMentoringRecordById($id: uuid!) {
		delete_mentoring_records_by_pk(id: $id) {
			id
		}
	}
`;
export const GET_MENTORING_RECORDS_BY_TEAM_BY_MONTH = gql`
	query GetMentoringRecordsByTeamByMonth(
		$team_id: uuid!
		$first_day: timestamptz!
		$last_day: timestamptz!
	) {
		mentoring_records(
			where: { _and: { team_id: { _eq: $team_id }, date: { _gte: $first_day, _lte: $last_day } } }
		) {
			id
			duration
			date
			record
			members {
				member_id
				member {
					id
					names(order_by: { created_at: desc }, limit: 1) {
						name
					}
				}
			}
		}
	}
`;
export const INVITE_TO_TEAM_BY_EMAIL = gql`
	mutation InviteEmailToTeam($email: String!, $team_id: String!) {
		inviteUserToTeam(user_email: $email, team_id: $team_id) {
			invite_id
			team_id
			user_id
		}
	}
`;
export const GET_INVITES_OF_TEAM = gql`
	query GetInvitesOfTeam($team_id: uuid!) {
		users: team_invites(where: { _and: { team_id: { _eq: $team_id } }, status: { _eq: pending } }) {
			user {
				id
				names(order_by: { created_at: desc }, limit: 1) {
					name
				}
			}
		}
		emails: user_invites(
			where: { _and: { invited_by: { _eq: $team_id } }, consumed: { _is_null: true } }
		) {
			email
		}
	}
`;

export const GET_TEAMS = gql`
	query GetTeams {
		teams {
			id
		}
	}
`;

export const GET_LOCATIONS = gql`
	query GetLocations {
		locations {
			id
			names {
				name
			}
		}
	}
`;

export const GET_GROUPS = gql`
	query GetGroups {
		groups {
			names {
				name
			}
			id
		}
	}
`;

export const GET_TEAMS_BY_GROUP = gql`
	${TEAM_CORE}
	query GetTeamsByGroup($groupId: uuid!) {
		teams(where: { groups: { group: { id: { _eq: $groupId } } } }) {
			...TeamCore
			name
		}
	}
`;

export const GET_GROUPS_BY_USER = gql`
	query GroupsByUser($user_id: String!) {
		users(where: { id: { _eq: $user_id } }) {
			teams {
				team {
					groups {
						group {
							id
							names {
								name
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_INVITE_STATE = gql`
	query GetInviteState($email: String!, $token: String!) {
		checkInviteStatus(email: $email, token: $token) {
			id
			status
		}
	}
`;

export const CONTENT_WITH_PROGRESS = gql`
	query ContentWithProgress($team_id: uuid!) {
		contents(where: { groups: { group: { teams: { team: { id: { _eq: $team_id } } } } } }) {
			sections {
				section {
					id
					name
					slug

					section_icons_files {
						id
						type
						file {
							id
							path
						}
					}

					outputs(order_by: { order: asc }) {
						id
						name
						slug
						icons {
							id
							type
							file {
								id
								path
							}
						}

						tools(order_by: { order: asc }) {
							tool {
								id
								name
								slug
								intro
								explanation
								slug
								points
								icons {
									id
									type
									file {
										id
										path
									}
								}

								questionnaires {
									teams_states(where: { team_id: { _eq: $team_id } }) {
										finished
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
