import { gql } from '@apollo/client/core';

export const SECTION_CORE = gql`
	fragment SectionCore on sections {
		id
		name
		slug
		hex_color
		intro
		section_icons_files {
			type
			file {
				path
			}
		}
	}
`;
export const OUTPUT_CORE = gql`
	fragment OutputCore on outputs {
		id
		intro
		name
		slug
		order
		icons {
			type
			file {
				path
			}
		}
	}
`;
export const TOOL_CORE = gql`
	fragment ToolCore on tools {
		id
		name
		intro
		explanation
		slug
		points
		icons {
			type
			file {
				path
			}
		}
	}
`;
export const MULTIMEDIA_CONTENT_FULL = gql`
	fragment MultimediaContentFull on multimedia_contents {
		ebooks_link
		resources
		video_lessons {
			link
		}
	}
`;
export const USER_PRIVATE_INFO = gql`
	fragment UserPrivateInfo on users {
		cpf
		created_at
		updated_at
		deactivated_since
		role
	}
`;
export const USER_PUBLIC_INFO = gql`
	fragment UserPublicInfo on users {
		id
		email
		names(order_by: { created_at: desc }) {
			name
		}
		profile_picture {
			path
		}
		social_media {
			facebook
			instagram
			linkedin
			other
			youtube
			twitter
		}
		occupation
	}
`;
export const TEAM_CORE = gql`
	fragment TeamCore on teams {
		description
		id
		logo {
			path
		}
		names(order_by: { created_at: desc }) {
			name
		}
		tags {
			tag {
				value
			}
		}
		social_media {
			facebook
			instagram
			linkedin
			other
			youtube
			twitter
		}
		members {
			user {
				id
				names(order_by: { created_at: desc }) {
					name
				}
			}
		}
		cnpj
	}
`;
export const GROUP_CORE = gql`
	fragment GroupCore on groups {
		id
		start_date
		end_date
		names(order_by: { created_at: desc }) {
			name
		}
		location_id
	}
`;
